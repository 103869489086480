//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from "lodash";
import { getEfficiencyColor, getbehaviorColor } from "@/commons";
import { mapState, mapActions } from "vuex";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from "axios";
export default {
  components: {
    Header: () => import("@/components/Header"),
    CContent: () => import("@/components/CContent"),
    CPages: () => import("@/components/CPages"),
    TeamInfoUser: () => import("./teaminfo-user"),
    AddTeam: () => import("./add-team"),
    JoinTeam: () => import("./join-team"),
    InvitationUser: () => import("./invitation-user"),
    BuyClient: () => import("./buy"),
  },
  data() {
    return {
      indexData: {
        type: "", // Add Edit ""
        name: "",
        row: null,
        xModalName: "productInfo",
      },
      isDownloading: false,
      progressNum: 0,
      teamPackageDialogVisiable: false,
      teamPackageDialogTime: null,
      teamValue: 2, //选择的团队
      selRow: null, //选择的团队
      searchVal: null, //搜索的内容
      isInfoView: false, //是否是详情页面
      loading: false,
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
      tabList: [
        {
          value: 1,
          label: "创建团队",
        },
        {
          value: 2,
          label: "邀请成员加入",
        },
        {
          value: 3,
          label: "加入团队",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    this.getTeamList();
    console.log(this.user);
  },
  methods: {
    getClientSKUDetail(row) {
      var info = "";
      info += "设备数量：" + row.ClientCount + "<br/>";
      info +=
        "存储方式：" +
        (row.IsEnableLocalStore ? "本地存储" : "服务器") +
        "<br/>";
      info += "额外功能：" + row.Functions + "<br/>";
      var dueTime = row.ClientDueTime
        ? row.ClientDueTime.timeFormat("yyyy-MM-dd HH:mm")
        : "永久有效";
      info += "截止日期：" + dueTime + "<br/>";
      return info;
    },
    getStorageSKUDetail(row) {
      var info = "";
      info += "存储量：" + row.Storage + "MB<br/>";
      var dueTime = row.StorageDueTime
        ? row.StorageDueTime.timeFormat("yyyy-MM-dd HH:mm")
        : "永久有效";
      info += "截止日期：" + dueTime + "<br/>";
      return info;
    },
    progressBar(progressEvent) {
      // 获取百分比（此处保留了一位小数）
      let percentage = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
      );
      this.$nextTick(() => {
        this.progressNum = percentage;
      });

      // 让百分比显示在界面上
    },
    downloadTeamPackage(row) {
      this.isDownloading = true;
      const getFile = (url, callback) => {
        return new Promise((resolve, reject) => {
          axios({
            method: "get", //使用get请求
            url, //放入的文件地址
            responseType: "arraybuffer", //请求的数据buffer对象
            onDownloadProgress: function (progressEvent) {
              //这一步是下载文件进度条，可以和progress进度条一块使用，不过多个文件下载会有很丑的动画，建议同步下载显示进度条
              // 对原生进度事件的处理
              if (callback) {
                callback(progressEvent);
              }
            },
          })
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error.toString());
            });
        });
      };
      this.$http
        .post("/Teams/GetTeamPackageInfo.ashx", { teamId: row.Id })
        .then((resp) => {
          if (resp.res == 0) {
            var teamId = resp.data.Id;
            const zip = new JSZip();
            zip.file("setup.ini", `[TeamInfo]\r\nId=${teamId}`);
            const promises = [];
            const promise = getFile(
              "/EZTeam-Installer.exe",
              this.progressBar
            ).then((data) => {
              zip.file("EZTeam-Installer.exe", data);
            });
            promises.push(promise);
            Promise.all(promises)
              .then(() => {
                zip
                  .generateAsync({ type: "blob" })
                  .then(function (content) {
                    saveAs(content, "EZTeam-Installer.zip");
                  })
                  .catch((err) => {
                    this.$message({
                      message: "未知异常，请稍后重试",
                      type: "error",
                    });
                  })
                  .finally(() => {
                    this.isDownloading = false;
                  });
              })
              .catch(() => {
                this.isDownloading = false;
              });
          }
        })
        .catch((err) => {
          this.$message({
            message: "未知异常，请稍后重试",
            type: "error",
          });
          this.isDownloading = false;
        });
    },
    PadZero(str) {
      //补零
      return new RegExp(/^\d$/g).test(str) ? `0${str}` : str;
    },
    formatTimeHms(timeSeconds) {
      if (timeSeconds) {
        let _seconds = parseInt(timeSeconds);
        let hours, mins, seconds;
        let result = "";
        seconds = parseInt(_seconds % 60);
        mins = parseInt((_seconds % 3600) / 60);
        hours = parseInt(_seconds / 3600);
        if (hours) {
          result =
            this.PadZero(hours) +
            ":" +
            this.PadZero(mins) +
            ":" +
            this.PadZero(seconds);
        } else {
          result = "00:" + this.PadZero(mins) + ":" + this.PadZero(seconds);
        }

        return result;
      } else {
        return null;
      }
    },
    downloadTeamPackageServer(row) {
      this.isDownloading = true;
      this.teamPackageDialogVisiable = true;
      this.teamPackageDialogTime = 0;
      var intervalId = setInterval(() => {
        this.teamPackageDialogTime += 1;
      }, 1000);
      this.$http
        .post("/Teams/CreateSetupClient.ashx", { teamId: row.Id })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamPackageDialogVisiable = false;
            clearInterval(intervalId);
            var path = resp.data;
            window.open(`${process.env.VUE_APP_CDNURL}/${path}`, "_self");
            // var ele = document.createElement("a");
            // ele.href = `${process.env.VUE_APP_DIC}/${path}`;
            // //ele.setAttribute("target", "_blank");
            // ele.click();
          }
        })
        .catch((error) => {
          this.$message({
            message: "未知异常，请稍后重试",
            type: "error",
          });
        })
        .finally(() => {
          this.teamPackageDialogVisiable = false;
          clearInterval(intervalId);
          this.isDownloading = false;
        });
    },
    getEfficiencyColor,
    getbehaviorColor,
    ...mapActions(["user_setUser"]),
    /**
     * 添加团队成功
     */
    addTeamSuccess(id) {
      this.handleSearch();
      //如果是加的第一个团队，则重新存个人信息
      if (this.user.TeamCount == 0) {
        this.$http
          .get("/Management/UserManagement/GetUserDetail.ashx", {
            params: { usId: null },
          })
          .then((resp) => {
            if (resp.res == 0) {
              this.user_setUser(resp.data);
              if (this.user.TeamCount == 0) {
                this.$router.push("/teamManagement");
              }
            }
          });
        if (id) {
          this.selRow = {
            Id: id,
          };
          this.isInfoView = true;
        } else {
          this.$message({
            type: "warning",
            message: `等待管理员审核通过`,
          });
        }
      }
    },
    /**
     * 搜索
     */
    handleSearch() {
      this.pageData.pageIndex = 1;
      this.getTeamList();
    },
    /**
     * 获取团队列表
     */
    getTeamList() {
      this.loading = true;
      const data = {
        searchText: this.searchVal,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        type: this.teamValue,
      };
      this.$http
        .get("/Teams/TeamManagement.ashx", { params: data })
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.data;
            this.pageData.totalNum = resp.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    setDefaultTeam(val) {
      this.$confirm("此操作将设置该团队为默认团队, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/User/SettingDefaultTeam.ashx", { teamId: val.Id })
            .then((resp) => {
              if (resp.res == 0) {
                this.user.DefaultTeamId = val.Id;
                this.$message({
                  type: "success",
                  message: `设置成功！`,
                });
                this.getTeamList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 查看详情
     */
    queryTeamManagementDetail(val) {
      this.selRow = val;
      this.isInfoView = true;
    },
    /**
     * 删除
     */
    handleDeleteRow(val) {
      this.$confirm("此操作将删除该团队, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Teams/DelTeam.ashx", { ids: [val.Id], operation: -1 })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: `删除成功！`,
                });
                this.getTeamList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 右边顶部更多操作
     * 2 邀请他人  1 创建团队  3 加入团队
     */
    tabChange(val) {
      if (val == 1) {
        this.$modal.show("addTeam");
      } else if (val == 3) {
        this.$modal.show("joinTeam");
      } else if (val == 2) {
        this.$modal.show("invitation");
      }
    },
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getTeamList();
    },
    /**
     * 返回列表页
     */
    viewChange() {
      this.isInfoView = false;
    },
    /**
     * 购买设备
     */
    handleBuyClient(row) {
      this.openWin("", row);
    },
    // 打开购买窗口
    openWin(ty, obj) {
      var type = "";
      if (ty != "") {
        type = ty === "ad" ? "Add" : "Edit";
      }
      this.indexData.type = type;
      if (ty != "ad") {
        this.indexData.row = obj;
      } else {
        this.indexData.row = null;
      }
      this.$modal.show("productInfo");
      this.$nextTick(() => {
        this.$refs.productInfo.changeEditState();
      });
    },
  },
};
